@tailwind base;
@tailwind components;
@tailwind utilities;

/* prevent pull-to-refresh for Safari 16+ */
@media screen and (pointer: coarse) {
  @supports (
    -webkit-backdrop-filter: blur(1px) and (overscroll-behavior-y: none)
  ) {
    html {
      min-height: 100.3%;
      overscroll-behavior-y: none;
    }
  }
}

/* prevent pull-to-refresh for Safari 9~15 */
@media screen and (pointer: coarse) {
  @supports (-webkit-backdrop-filter: blur(1px)) and
    (not (overscroll-behavior-y: none)) {
    html {
      height: 100%;
      overflow: hidden;
    }
    body {
      margin: 0px;
      max-height: 100%; /* or `height: calc(100% - 16px);` if body has default margin */
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
    /* in this case to disable pinch-zoom, set `touch-action: pan-x pan-y;` on `body` instead of `html` */
  }
}

/* prevent pull-to-refresh for Chrome 63+ */
body {
  overscroll-behavior-y: none;
}

body,
input {
  @apply bg-white dark:bg-gray-900;
}

input {
  @apply dark:border-gray-300;
}

.clickable {
  @apply rounded-md hover:bg-gray-100 dark:hover:bg-gray-800;
}

.card {
  @apply truncate border 
  border-gray-200 bg-gray-50 
  px-3 py-3 text-lg font-semibold capitalize 
  dark:border-gray-700 dark:bg-gray-900;
}

.button {
  @apply flex shrink-0 justify-center rounded-md px-3  py-1.5 text-sm font-bold hover:cursor-pointer;
}

.input {
  @apply h-9 rounded-md border p-1;
}

.primary {
  @apply bg-lime-700 text-gray-100 disabled:bg-gray-400 dark:bg-lime-400 dark:text-gray-900;
}

.danger {
  @apply bg-red-700 text-gray-100;
}
